import * as $ from 'jquery';

$(document).ready(function() {
  $('#request_general_information_machine_id').change(function (e) {
    var status = $(e.target).find('option:selected').data('status')
    var $element = $('#machine_warning_alert')

    if(status === 'up'){
      $element.removeClass('d-block').addClass('d-none')
    } else if(status === 'down'){
      $element.removeClass('d-none').addClass('d-block')
    } else {
      $element.removeClass('d-block').addClass('d-none')
    }
  })
});