/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// We're including this file in app/views/layouts/application.html.haml - it
// should mostly only include application-wide Javascript. You should separate
// your Javascript into sensible files / components then make a decision how best
// to compose them to serve to the client.
//
// For example:
// The home page has a specific slideshow, some sort of calculator, an ad rotator etc.
// You could split these into calculator.js, slideshow.js and rotator.js then
// include them in a home.js pack which is only included on the home page.

import 'react-app-polyfill/ie11';

import * as Sentry from '@sentry/browser';
import Rails from "rails-ujs";
import 'bootstrap';
import bsCustomFileInput from 'bs-custom-file-input';
import { AjaxModal, VisibilityMap } from '@epigenesys/epi-js';

Rails.start();

// Notify Sentry of any js errors
if ( process.env.NODE_ENV === 'production' ) {
  Sentry.init({dsn: "https://ce83160b72a64992af8319f701551956@sentry.epigenesys.org.uk/40"});
}

Sentry.configureScope(scope => {
  scope.setUser({ id: document.querySelector('body').dataset.userId });
});

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

window.addEventListener('DOMContentLoaded', () => bsCustomFileInput.init())

import 'scripts/machine_warning'

$(document).ready(function() {
  $('.hide-delivered').on('change', function() {
    $('tr[data-status="delivered"]', $(this).closest('table')).toggle(!$(this).prop('checked'))
  })
})
